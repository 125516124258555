import type { NextPage } from 'next';

import { useStaffMe } from '@2ndmarket/services';

import { Panel } from '../sections';
import { BackofficeSeo, Loading } from '../components';
import { useEffect } from 'react';

const Home: NextPage = () => {
  const { data: userStaff, loading, error } = useStaffMe();

  useEffect(() => {
    if (!userStaff) {
      return;
    }
  }, [userStaff]);

  return (
    <>
      <BackofficeSeo
        title="Dashboard"
        description="Backoffice"
      />
      {userStaff && !error ? (
        <Panel />
      ) : (
        <Loading loading={loading} />
      )}
    </>
  );
};

export default Home;
